.accordion-button:not(.collapsed) {
    background: transparent !important;
    color: #000;
    box-shadow: none;
}

.accordion-button {
    background-color: #e5e9f2;
}

.accordion-item:not(:first-of-type) {
    border-top: 1px solid #dee2e6 !important;
}

.accordion-header:last-of-type .accordion-button {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}

.line-clamp-1 {
    font-size: 14px;
}

.pointer {
    cursor: pointer;
}

.text-title {
    color: #34364a;
    font-weight: bold;
    font-size: 18px !important;
}

.active-card {
    background-color: #34364a;
    color: #fff;
}

.main-wrapper {
    margin-left: 120px;
    margin-right: auto;
    min-height: 100vh;
    overflow: hidden;
    padding: 95px 30px 30px 0px;
    position: relative;
    transition: all .3s;
    width: 100%;
}

.embed-responsive {
    display: block;
    height: 80vh;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 100%;
}

.video-iframe,
.plyr--video {
    border-radius: 16px;
    transition: all .3s;
}

.embed-responsive-16by9:before {
    padding-top: 56.25%;
}

.embed-responsive:before {
    content: "";
    display: block;
}

.judul-kelas {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    margin-bottom: 10px;
}

.fw-light {
    font-weight: 400 !important;
}

.card-selected {
    background-color: #34364a !important;
    color: #f6f8fd !important;
}
.dyntube-iframe-container-playlist {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 56.25%;
}

.dyntube-responsive-iframe-playlist {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 80vh;
    border: none;
}